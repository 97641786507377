require('./bootstrap');
require('./ajaxFormFiles');
require('./cart');
require('./timetable');
require('./clientZone');
require('./hallReservation');
require('./cookie-aggrement');

function onDomReady(fn) {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        setTimeout(fn, 1);
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

function bindMobileMenu() {
    const burgerButton = document.getElementById('mobile_menu_icon');

    if (burgerButton) {
        burgerButton.onclick = () => {
            const mobileMenu = document.getElementById('mobile_menu_burger');

            if (mobileMenu.classList.contains('show')) {
                mobileMenu.classList.remove('show');
                burgerButton.classList.remove('show');
            } else {
                mobileMenu.classList.add('show');
                burgerButton.classList.add('show');
            }
        };
    }
}

window.addEventListener('load', () => {
    $.datetimepicker.setLocale(document.documentElement.lang);

    const d = new Date();
    d.setFullYear(d.getFullYear() - 16);
    const strDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

    $('.datepicker').datetimepicker({
        startDate: strDate,
        maxDate: strDate,
        format: 'Y-m-d',
        timepicker: false,
    });
});

bindMobileMenu();

function showModal(modal, callback) {
    if (modal) {
        const forms = modal.querySelectorAll('form');

        for (const form of forms) {
            const generalErrors = form.querySelectorAll('.general-error');
            for (const el of generalErrors) {
                el.remove();
            }

            const generalSuccess = form.querySelectorAll('.general-success');
            for (const el of generalSuccess) {
                el.remove();
            }

            const fields = form.querySelectorAll('.input');
            for (const el of fields) {
                el.classList.remove('invalid');

                if (el.classList.contains('clear-before') && !el.classList.contains('ignore-clear-before')) {
                    el.value = '';
                }

                if (el.hasAttribute('data-reset-to')) {
                    el.value = el.getAttribute('data-reset-to');
                }
            }

            const hiddenFields = form.querySelectorAll('.axios-hide');
            for (const el of hiddenFields) {
                el.classList.remove('d-none');
            }

            const messageFields = form.querySelectorAll('.input-error');
            for (const el of messageFields) {
                el.remove();
            }
        }

        modal.classList.add('modal-show');

        let op = 0.1;
        modal.style.display = 'block';
        const timer = setInterval(() => {
            if (op >= 1) {
                clearInterval(timer);

                if (callback) {
                    callback();
                }
            }
            modal.style.opacity = op;
            modal.style.filter = `alpha(opacity=${op * 100})`;
            op += op * 0.1;
        }, 5);

        document.body.classList.add('modal-visible');
    }
}

function hideModal(modal, callback) {
    if (modal) {
        modal.classList.remove('modal-show');

        let op = 1;
        const timer = setInterval(() => {
            if (op <= 0.1) {
                clearInterval(timer);
                modal.style.display = 'none';

                if (callback) {
                    callback();
                }
            }
            modal.style.opacity = op;
            modal.style.filter = `alpha(opacity=${op * 100})`;
            op -= op * 0.1;
        }, 5);
    }

    document.body.classList.remove('modal-visible');
}

function initModals() {
    const modalsButtons = document.querySelectorAll('.modal-btn');
    for (const el of modalsButtons) {
        el.addEventListener('click', (e) => {
            if (el.hasAttribute('data-modal') && !el.classList.contains('prevent-modal')) {
                e.preventDefault();

                const modal = document.getElementById(el.getAttribute('data-modal'));

                if (modal) {
                    if (el.hasAttribute('data-modal-target')) {
                        const modalTarget = modal.querySelector(`[data-tab=${el.getAttribute('data-modal-target')}]`);

                        if (modalTarget) {
                            modalTarget.click();
                        }
                    }

                    if (modal.classList.contains('modal-show')) {
                        hideModal(modal);
                    } else {
                        showModal(modal);
                    }
                }
            }
        });
    }

    const modals = document.querySelectorAll('.modal-identification');
    for (const el of modals) {
        const modalScrollContainer = el.querySelector('.modal-scroll-container');
        const modalDialogContainer = el.querySelector('.modal-dialog');
        const modalContent = el.querySelector('.modal-content');

        for (const closeBtn of el.querySelectorAll('.modal-close')) {
            closeBtn.addEventListener('click', () => {
                hideModal(el);
            });
        }

        if (modalScrollContainer && !el.classList.contains('static-backdrop')) {
            modalScrollContainer.addEventListener('mousedown', (e) => {
                if (e.target === modalScrollContainer) {
                    hideModal(el);
                }
            });
        }

        if (modalDialogContainer && !el.classList.contains('static-backdrop')) {
            modalDialogContainer.addEventListener('mousedown', (e) => {
                if (e.target === modalDialogContainer) {
                    hideModal(el);
                }
            });
        }

        if (modalContent) {
            modalContent.addEventListener('click', (e) => {
                e.stopPropagation();
            });
        }
    }
}

window.showModal = (modal, callback) => showModal(modal, callback);
window.hideModal = (modal, callback) => hideModal(modal, callback);

onDomReady(() => {
    initModals();
});
