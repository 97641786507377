function setApproximatePrice() {
    const toCheck = document.getElementById('additional_people_list')
        .querySelectorAll('.to-check-group');
    const uniqueGroups = [];
    let sumOfUnique = 0;
    let dividedOfUniqueSum = 0;
    let sumOfTimes = 0;

    const rootGroup = document.getElementById('root_group').value;
    uniqueGroups[rootGroup] = rootGroup;

    toCheck.forEach((content) => {
        uniqueGroups[content.value] = content.value;
    });

    for (const key in uniqueGroups) {
        if (uniqueGroups.hasOwnProperty(key)) {
            sumOfUnique += parseFloat(key);
        }
    }

    const uniqueGroupsSize = Object.getOwnPropertyNames(uniqueGroups).length - 1;
    const times = parseInt(document.getElementById('the_count').value) + 1;
    const typeMultiply = parseInt(document.getElementById('the_type').value);

    dividedOfUniqueSum = sumOfUnique / uniqueGroupsSize;
    sumOfTimes = dividedOfUniqueSum * times * typeMultiply;

    document.getElementById('the_price').innerHTML = `${sumOfTimes.toFixed(2)} €`;
}

function bindReservationAddAdditional() {
    const additionalButton = document.getElementById('hall_add_additional');

    if (additionalButton) {
        additionalButton.onclick = () => {
            const template = document.getElementById('additional_template')
                .cloneNode(true);
            template.removeAttribute('id');

            template.querySelector('.to-check-group')
                .addEventListener('change', () => {
                    setApproximatePrice();
                });

            template.querySelector('.hall-additional-remove')
                .addEventListener('click', () => {
                    template.remove();
                    setApproximatePrice();
                });

            document.getElementById('additional_people_list')
                .appendChild(template);
            setApproximatePrice();
        };
    }
}

function bindChangeRootGroup() {
    const groupElement = document.getElementById('root_group');

    if (groupElement) {
        groupElement.onchange = () => {
            const newValue = groupElement.value;
            document.getElementById('group_price')
                .setAttribute('data-price', newValue);
            setApproximatePrice();
        };
    }
}

function bindChangeRootType() {
    const typeElement = document.getElementById('the_type');

    if (typeElement) {
        typeElement.onchange = () => {
            setApproximatePrice();
        };
    }
}

function bindChangeTime() {
    const groupElement = document.getElementById('the_count');

    if (groupElement) {
        groupElement.onchange = () => {
            setApproximatePrice();
        };
    }
}

function bindHallTypeCountChange() {
    document.onchange = (e) => {
        if (e.target.classList.contains('hall-type-select')) {
            let parent;
            if (e.target.classList.contains('inner-select')) {
                parent = e.target.parentNode.parentNode.parentNode;
            } else {
                parent = e.target.parentNode.parentNode;
            }

            for (const timeOption of parent.querySelectorAll('.hall-count-option')) {
                timeOption.removeAttribute('disabled');
            }

            if (e.target.value === '2') {
                let disableOnwards = false;
                for (const timeOption of parent.querySelectorAll('.hall-count-option')) {
                    if (disableOnwards || timeOption.getAttribute('data-half-reserved') === '1') {
                        timeOption.setAttribute('disabled', 'true');
                        disableOnwards = true;
                    }
                }
            }
        }
    };
}

bindReservationAddAdditional();
bindChangeRootGroup();
bindChangeTime();
bindHallTypeCountChange();
bindChangeRootType();
