import axios from 'axios';

function bindAuthorizationTabs() {
    const tabs = document.querySelectorAll('.visibility-tab');
    for (const el of tabs) {
        el.addEventListener('click', () => {
            const tabID = el.getAttribute('data-id');
            tabs.forEach((it) => {
                it.classList.remove('active');
            });
            el.classList.add('active');

            const tabContents = document.querySelectorAll('.visibility-tab-content');
            tabContents.forEach((content) => {
                content.classList.remove('active');
            });
            const tabContent = document.getElementById(`visibility-tab-content-${tabID}`);

            if (tabContent) {
                tabContent.classList.add('active');
            }

            const fields = document.querySelectorAll('.form-field');
            for (const el of fields) {
                const input = el.querySelector('input') ?? el.querySelector('select') ?? el.querySelector('textarea');
                const errorField = el.querySelector('.field-error');

                if (input) {
                    el.classList.remove('has-error');

                    if (errorField) {
                        errorField.classList.remove('show');
                        errorField.innerHTML = '';
                    }
                }
            }
        });
    }
}

function bindPhotoChange() {
    const changePhotoLink = document.getElementById('photo_change_link');
    const photoLoader = document.getElementById('photo_loader');

    if (changePhotoLink) {
        changePhotoLink.onchange = async (e) => {
            const form = e.target.parentNode.parentNode;
            const url = form.action;
            const data = new FormData();

            photoLoader.classList.add('show');

            for (let i = 0; i < form.elements.length; i++) {
                if (form.elements[i].type !== 'submit') {
                    if (form.elements[i].type === 'file') {
                        data.append(form.elements[i].name, form.elements[i].files[0]);
                    }
                }
            }

            try {
                const response = await axios.post(url, data, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                if (response.data.data.alert) {
                    // eslint-disable-next-line no-alert
                    alert(response.data.data.alert);
                }

                if (response.data.data.new_photo) {
                    document.getElementById('client_current_photo').src = `/images/${response.data.data.new_photo}`;
                    document.getElementById('photo_remove_link').style.display = 'block';
                }

                photoLoader.classList.remove('show');
            } catch (error) {
                photoLoader.classList.remove('show');

                if (error.response && error.response.data && error.response.data.data) {
                    if (error.response.data.data.alert) {
                        // eslint-disable-next-line no-alert
                        alert(error.response.data.data.alert);
                    }
                }
            }
        };
    }
}

function bindPhotoRemove() {
    const removePhotoLink = document.getElementById('photo_remove_link');

    if (removePhotoLink) {
        removePhotoLink.onclick = async () => {
            try {
                await axios.get('/client-remove-photo');
                document.getElementById('client_current_photo').src = '/photo_placeholder.svg';
                document.getElementById('photo_remove_link').style.display = 'none';
            } catch (error) {
                console.error('Failed removing photo', error);
            }
        };
    }
}

function bindChangeGroup() {
    const groupElement = document.getElementById('group_element');

    if (groupElement) {
        groupElement.onchange = () => {
            if (parseInt(groupElement.value) === 43) {
                document.getElementById('student_code').style.display = 'flex';
                document.getElementById('date_field').classList.remove('field-50');
                document.getElementById('date_field').classList.add('field-25');
            } else {
                document.getElementById('student_code').style.display = 'none';
                document.getElementById('date_field').classList.remove('field-25');
                document.getElementById('date_field').classList.add('field-50');
            }
        };
    }
}

function bindShowHallClients() {
    const reservations = document.querySelectorAll('.registration-holder');
    for (const el of reservations) {
        el.addEventListener('click', () => {
            const isOpen = el.classList.contains('show');
            const hasClients = el.classList.contains('has-clients');
            const res = document.querySelectorAll('.registration-holder');
            res.forEach((content) => {
                content.classList.remove('show');
            });

            if (hasClients) {
                if (isOpen) {
                    el.classList.remove('show');
                } else {
                    el.classList.add('show');
                }
            }
        });
    }
}

bindPhotoChange();
bindPhotoRemove();
bindAuthorizationTabs();
bindChangeGroup();
bindShowHallClients();
