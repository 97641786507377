import axios from 'axios';

function checkForCartItems() {
    const cart = document.getElementById('cart_items');
    const cartContainer = document.getElementById('cart_container');
    const toggleButton = document.getElementById('cart_toggle_button');
    if (cart.childElementCount > 0) {
        toggleButton.classList.add('has-items');
    } else {
        toggleButton.classList.remove('toggled');
        toggleButton.classList.remove('has-items');
        cartContainer.classList.remove('show');
    }
}

function getProductTemplate() {
    const productTemplate = document.getElementById('cart_product_template').cloneNode(true);
    productTemplate.removeAttribute('id');
    productTemplate.style.display = '';
    return productTemplate;
}

function bindProductTemplate(data) {
    const cart = document.getElementById('cart_items');
    const cartCount = document.getElementById('cart_items_count');
    const totalPrice = document.getElementById('total_price');
    let itemsCount = 0;
    let currentTotal = 0;

    cart.innerHTML = '';

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const template = getProductTemplate();

            const item = data[key];
            const title = template.querySelectorAll('.product-title')[0];
            const count = template.querySelectorAll('.product-count')[0];
            const overall = template.querySelectorAll('.product-price-overall')[0];
            const price = template.querySelectorAll('.product-price')[0];
            const buttonUp = template.querySelectorAll('.product-count-up')[0];
            const buttonDown = template.querySelectorAll('.product-count-down')[0];
            const buttonRemove = template.querySelectorAll('.product-remove')[0];

            currentTotal += parseFloat(item[0].product_price) * item.length;

            buttonUp.setAttribute('data-product-title', item[0].product_title);
            buttonUp.setAttribute('data-product-id', item[0].product_id);
            buttonUp.setAttribute('data-product-price', item[0].product_price);
            buttonDown.setAttribute('data-product-id', item[0].product_id);
            buttonRemove.setAttribute('data-product-id', item[0].product_id);

            buttonUp.addEventListener('click', async () => {
                const response = await axios.post('/add-to-cart', {
                    product_title: item[0].product_title,
                    product_id: item[0].product_id,
                    product_price: item[0].product_price,
                });

                if (response.data.success) {
                    count.innerHTML = parseInt(count.innerHTML) + 1;
                    overall.innerHTML = `${(parseFloat(item[0].product_price) + parseFloat(overall.innerHTML)).toFixed(2)} €`;

                    const cartCurrentCount = parseInt(cartCount.getAttribute('data-product-count'));

                    cartCount.setAttribute('data-product-count', String(cartCurrentCount + 1));
                    cartCount.innerHTML = cartCurrentCount + 1 > 9 ? '9+' : cartCurrentCount + 1;

                    const totalCurrent = parseFloat(totalPrice.getAttribute('data-total-price')) + parseFloat(item[0].product_price);

                    totalPrice.setAttribute('data-total-price', totalCurrent.toFixed(2));
                    totalPrice.innerHTML = `${totalCurrent.toFixed(2)} €`;
                }
            });

            buttonRemove.addEventListener('click', async () => {
                const response = await axios.post('/remove-item-cart', {
                    product_id: item[0].product_id,
                });

                if (response.data.success) {
                    const cartCurrentCount = parseInt(cartCount.getAttribute('data-product-count'));

                    cartCount.setAttribute('data-product-count', String(cartCurrentCount - response.data.data));
                    cartCount.innerHTML = cartCurrentCount - response.data.data > 9 ? '9+' : cartCurrentCount - response.data.data;

                    template.parentNode.removeChild(template);

                    const totalCurrent = parseFloat(totalPrice.getAttribute('data-total-price')) - parseFloat(item[0].product_price) * response.data.data;

                    totalPrice.setAttribute('data-total-price', totalCurrent.toFixed(2));
                    totalPrice.innerHTML = `${totalCurrent.toFixed(2)} €`;

                    checkForCartItems();
                }
            });

            buttonDown.addEventListener('click', async () => {
                const response = await axios.post('/remove-sub-item-cart', {
                    product_id: item[0].product_id,
                });

                if (response.data.success) {
                    count.innerHTML = parseInt(count.innerHTML) - 1;
                    overall.innerHTML = `${(parseFloat(overall.innerHTML) - parseFloat(item[0].product_price)).toFixed(2)} €`;

                    const cartCurrentCount = parseInt(cartCount.getAttribute('data-product-count'));

                    cartCount.setAttribute('data-product-count', String(cartCurrentCount - 1));
                    cartCount.innerHTML = cartCurrentCount - 1 > 9 ? '9+' : cartCurrentCount - 1;

                    const totalCurrent = parseFloat(totalPrice.getAttribute('data-total-price')) - parseFloat(item[0].product_price);

                    totalPrice.setAttribute('data-total-price', totalCurrent.toFixed(2));
                    totalPrice.innerHTML = `${totalCurrent.toFixed(2)} €`;
                }
            });

            title.innerHTML = item[0].product_title;
            count.innerHTML = item.length;
            price.innerHTML = `${item[0].product_price} €`;
            overall.innerHTML = `${(item.length * parseFloat(item[0].product_price)).toFixed(2)} €`;
            cart.appendChild(template);
            itemsCount += item.length;
        }
    }

    cartCount.setAttribute('data-product-count', String(itemsCount));
    cartCount.innerHTML = itemsCount > 9 ? '9+' : itemsCount;

    totalPrice.setAttribute('data-total-price', currentTotal.toFixed(2));
    totalPrice.innerHTML = `${currentTotal.toFixed(2)} €`;

    checkForCartItems();
}

function bindMembershipOrderButton() {
    const buttons = document.querySelectorAll('.order-btn');

    for (const el of buttons) {
        el.addEventListener('click', async (e) => {
            e.preventDefault();

            const response = await axios.post('/add-to-cart', {
                product_title: el.getAttribute('data-product-title'),
                product_id: el.getAttribute('data-product-id'),
                product_price: el.getAttribute('data-product-price'),
            });

            if (response.data.success) {
                bindProductTemplate(response.data.data);
            }
        });
    }
}

function bindCartToggleButton() {
    const toggleButton = document.getElementById('cart_toggle_button');
    const closeButton = document.getElementById('close_container');
    const cart = document.getElementById('cart_container');

    closeButton.addEventListener('click', () => {
        toggleButton.classList.remove('toggled');
        cart.classList.remove('show');
    });

    toggleButton.addEventListener('click', () => {
        if (toggleButton.classList.contains('toggled')) {
            toggleButton.classList.remove('toggled');
            cart.classList.remove('show');
        } else {
            toggleButton.classList.add('toggled');
            cart.classList.add('show');
        }
    });
}

function initCart() {
    axios.get('/get-cart')
        .then((response) => {
            if (response.data.success) {
                bindProductTemplate(response.data.data);
                bindCartToggleButton();
            }
        });
}

bindProductTemplate();
bindMembershipOrderButton();
initCart();
